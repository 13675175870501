import React from "react";
import { FaStar } from "react-icons/fa"; // Importing the star icon for reviews
import Imgbg from "../images/bg/bg6.jpeg";

const Testimonial = () => {
  return (
    <div>
      {/* Banner Section */}
      <section
        className="page_banner bg-cover bg-center"
        style={{ backgroundImage: `url(${Imgbg})` }} // Use inline styles for dynamic image
      >
        <div className="container mx-auto py-12">
          <div className="flex flex-col lg:flex-row items-center justify-between">
            {/* Left Side Content */}
            <div className="lg:w-5/12 lg:ml-10 bg-white bg-opacity-70 p-8 rounded-lg shadow-lg">
              <h2 className="text-4xl lg:text-5xl font-bold text-gray-800">Testimonials</h2>
              <p className="breadcrumbs text-lg mt-4 ml-1 text-gray-600">
                <a href="/" className="text-black hover:text-blue-600">
                  Home
                </a>
                <span>/</span>Testimonials
              </p>
            </div>

            {/* Right Side Image (hidden on small screens) */}
            <div className="lg:w-6/12 mt-8 lg:mt-0">
              <img
                src="images/about/contactusbanner.png"
                alt=""
                className="w-full h-auto rounded-lg shadow-md"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Reviews Section */}
      <div className="review-section text-center mb-24">
        <h2 className="font-medium text-[#008ba8] mb-12 text-4xl">Customer Reviews</h2>

        {/* Reviews Grid */}
        <div className="flex flex-wrap justify-center gap-8">
          <div className="container mx-auto">
            <div className="flex flex-wrap justify-center gap-10">
              {/* Render Review Components */}
              {reviewsData.map((review, index) => (
                <Review
                  key={index}
                  name={review.name}
                  review={review.review}
                  rating={review.rating}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Review Component
const Review = ({ name, review, rating }) => {
  return (
    <div className="review w-full md:w-1/2 lg:w-1/3 mb-5 lg:p-[40px] transform transition duration-300 hover:scale-105 hover:shadow-lg rounded-lg bg-white p-6">
      <h3 className="text-xl font-semibold text-gray-800 mb-2">{name}</h3>
      <div className="rating text-yellow-500 mb-4">
        {/* Display stars */}
        {[...Array(rating)].map((_, index) => (
          <FaStar key={index} className="inline-block" />
        ))}
      </div>
      <p className="text-gray-600">{review}</p>
    </div>
  );
};

// Sample data for reviews
const reviewsData = [
  {
    name: "Rajalakshmi S",
    review:
      "Great service and attention, haircuts are very well done and they did an amazing job. They are expert and also know all the latest trends. Everyone was so friendly and ready to help.",
    rating: 5,
  },
  {
    name: "Lazee Charu",
    review:
      "I took acne treatment. After just one session, I noticed a visible improvement in my skin – reduced acne and redness, fewer breakouts, and a smoother texture. Highly recommended!",
    rating: 5,
  },
  {
    name: "Madhunisha. M",
    review: "Very much satisfied with my haircut. Nice ambiance. Fulfilled my expectations.",
    rating: 5,
  },
  {
    name: "Pragya Pavi",
    review:
      "I had an amazing experience at Unika Beauty Salon... I had smoothening service, it's really nice! I love it! Thanks to Krithika sis, very friendly and lovable staff. Highly recommend!",
    rating: 5,
  },
  {
    name: "Pavima Pavi",
    review:
      "Yesterday, I visited the salon and took some services. It was excellent and genuine. Highly recommend for good service and polite staff.",
    rating: 5,
  },
  {
    name: "Priyadharshini SM",
    review: "Had an amazing experience after the haircut. She is very kind. Must visit.",
    rating: 5,
  },
  {
    name: "Pamilla",
    review: "Unexpected hair styling 🥰🥰🥰 really loved it 👌👌👌👌👌.",
    rating: 5,
  },
  {
    name: "Priyadharshini SM",
    review: "Good hair spa service.",
    rating: 5 ,
  },
];

export default Testimonial;
