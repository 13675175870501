import React from "react";
import ContactBanner from "../images/bg/bg6.jpeg";
import ContactImage from "../images/bg/22.png";

const ContactUs = () => {
  return (
    <div>
       <section
        className="page_banner bg-cover bg-center"
        style={{ backgroundImage: `url(${ContactBanner})` }} // Use inline styles for dynamic image
      >
        <div className="container mx-auto py-12">
          <div className="flex flex-col lg:flex-row items-center justify-between">
            {/* Left Side Content */}
            <div className="lg:w-5/12 lg:ml-10 bg-white bg-opacity-70 p-8 rounded-lg shadow-lg">
              <h2 className="text-4xl lg:text-5xl font-bold text-gray-800">Contact</h2>
              <p className="breadcrumbs text-lg mt-4 ml-1 text-gray-600">
                <a href="/" className="text-black hover:text-blue-600">
                  Home
                </a>
                <span>/</span>Contact
              </p>
            </div>

            {/* Right Side Image (hidden on small screens) */}
            <div className="lg:w-6/12 mt-8 lg:mt-0">
              <img
                src="images/about/contactusbanner.png"
                alt=""
                className="w-full h-auto rounded-lg shadow-md"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="commonSection appointmentSection">
        <div className="container mx-auto">
          <div className="row">
            <div className="col-lg-12">
              <div className="appointment_area">
                <div className="row">
                  {/* Uncomment and fill in the appointment form as needed
                  <div className="col-lg-7 col-md-7">
                    <div className="appointment_form">
                      <h3>Book an Appointment</h3>
                      <form id="frmContactus" method="post" className="row">
                        <div className="input-field col-lg-6">
                          <input type="text" name="Full_Name" id="Full_Name" placeholder="Name" required className="p-2 border border-gray-300 rounded" />
                        </div>
                        <div className="input-field col-lg-6">
                          <input type="email" name="Email_Address" id="Email_Address" placeholder="E-mail" required className="p-2 border border-gray-300 rounded" />
                        </div>
                        <div className="input-field col-lg-6">
                          <input type="number" name="Telephone_Number" id="Telephone_Number" placeholder="Phone Number" required className="p-2 border border-gray-300 rounded" />
                        </div>
                        <div className="input-field col-lg-6">
                          <select name="Your_Subject" id="Your_Subject" required className="p-2 border border-gray-300 rounded">
                            <option value="" disabled selected>Select Brand</option>
                            <option value="Yolo">Yolo</option>
                            <option value="Maha Elegance">Maha Elegance</option>
                            <option value="Maha Training Academy">Maha Training Academy</option>
                            <option value="Thoya">Thoya</option>
                            <option value="Maha Hair Fixing Center">Maha Hair Fixing Center</option>
                          </select>
                        </div>
                        <div className="input-field col-lg-12">
                          <input 
                            type="text" 
                            name="date_time" 
                            id="date_time" 
                            placeholder="Select Date & Time" 
                            required 
                            className="p-2 border border-gray-300 rounded"
                          />
                          <i className="fas fa-calendar-alt"></i>
                        </div>
                        <div className="input-field col-lg-12">
                          <textarea 
                            name="Your_Message" 
                            id="Your_Message" 
                            placeholder="Your Message" 
                            required 
                            className="p-2 border border-gray-300 rounded"
                          ></textarea>
                        </div>
                        <div className="input-field col-lg-12">
                          <button 
                            name="submit" 
                            id="submit" 
                            type="submit" 
                            className="mo_btn p-2 bg-primary text-white rounded"
                          >
                            <i className="fas fa-calendar-plus"></i> Book An Appointment
                          </button>
                          <h4 className="text-[#704182] text-xl mt-2" id="msg"></h4>
                        </div>
                      </form>
                    </div>
                  </div>
                  */}

                  <div
                    className="col-lg-4 col-md-3  bg-cover lg:flex lg:gap-[70px]"
                    style={{ backgroundImage: `url(${ContactImage})` }}
                  >
                    <div className="icon_box_01 p-5">
                      <h4>
                        <i className="fas fa-map-marker-alt"></i> Location:
                      </h4>
                      <p>
                        NO:131 1st floor DB road(near ramraj cotton showroom) RS
                        puram , coimbatore 641002
                      </p>
                    </div>
                    <div className="icon_box_01 p-5">
                      <h4>
                        <i className="fas fa-phone-alt"></i> Phone:
                      </h4>
                      <p><h5 className=" font-bold text-[20px]">Coimbatore Salon</h5>+91 70927 70199</p>
                      <p><h5 className=" font-bold text-[20px]">Madurai Salon</h5>+91 70927 70399</p>
                    </div>
                    <div className="icon_box_01 p-5">
                      <h4>
                        <i className="fas fa-envelope"></i> Email:
                      </h4>
                      <p>unikaabeauty@gmail.com</p>
                    </div>
                    <div className="icon_box_01 p-5">
                      <h4>
                        <i className="fas fa-clock"></i> Hours:
                      </h4>
                      <p>9:00 am - 9:00 pm</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d125322.5134688492!2d76.88483292123823!3d11.013957787984479!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4a860413f0f039d3%3A0xe66d3454461e5e76!2sUNIKAA%20BEAUTY%20SALON!5e0!3m2!1sen!2sin!4v1732354433283!5m2!1sen!2sin"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
