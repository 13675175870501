// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Navbar.jsx';
import Home from './pages/Home';
import About from './pages/About';
import Training from './pages/Training';
import Gallery from './pages/Gallery';
import Franchise from './pages/Franchise';
import Testimonial from './pages/Testimonial';
import ContactUs from './pages/ContactUs';
import Footer from './components/Footer.jsx';
import ScrollToTop from './pages/subpages/scrolltop.jsx';
import NotFound from './pages/NotFound.jsx';
import Ourservices from './pages/ourservices.jsx';
import TrainingAcademy from './pages/Trainingacademy.jsx';

const App = () => {
  return (
    <Router>

      <Header />
      <div className="pt-20"> {/* Adding padding-top to avoid content hiding behind header */}
        <Routes>
          <Route path="/" element={<Home />} />
          
          <Route path="/about" element={<About />} />
          <Route path="/training" element={<Training />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/franchise" element={<Franchise />} />
          <Route path="/testimonial" element={<Testimonial />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path='ourservices' element={<Ourservices/>}/>
          <Route path="/trainingacademy" element={<TrainingAcademy />} />
          {/* Add additional routes as needed */}
          <Route path="*" element={<NotFound />} />

        </Routes>
      </div>
      <ScrollToTop/>
    <Footer/>
    </Router >

  );
};

export default App;
