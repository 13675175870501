import React from 'react'

const NotFound = () => (
    <div className=' my-60 text-center'>
      <h2>Page Not Found</h2>
      <p>The page you are looking for doesn't exist.</p>
    </div>
  );
  

export default NotFound