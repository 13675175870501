import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import { motion } from 'framer-motion'; // Import Framer Motion
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './slider.css'; // Custom styling

const SliderComponent = () => {
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0); // Track the current slide index

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: 'linear',
    beforeChange: (current, next) => setCurrentSlide(next), // Update the current slide on change
  };

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  // Cube transition animation variants
  const cubeTransition = (isNext) => ({
    initial: { rotateX: isNext ? 90 : -90, opacity: 0 }, 
    animate: { rotateX:isNext ? 0 : 90, opacity: 1, transition: { duration: 1 } },
    exit: { rotateX: isNext ? -90 : 90, opacity: 0, transition: { duration: 1 } },
  });

  const cube1Transition = (isNext) => ({
    initial: { rotateY: isNext ? 90 : -90, opacity: 0 }, 
    animate: { rotateY:isNext ? 90: 0, opacity: 1, transition: { duration: 1 } },
    exit: { rotateY: isNext ? -90 : 90, opacity: 0, transition: { duration: 1 } },
  });


  const cube2Transition = (isNext) => ({
    initial: {
      rotateX: isNext ? 90 : -90, 
      rotateY: isNext ? -90 : 90, 
      opacity: 0,
    },
    animate: {
      rotateX: isNext ? 90 : 0,    
      rotateY: isNext ? 90 : 0,  
      opacity: 1,
      transition: { duration: 1 },
    },
    exit: {
      rotateX: isNext ? 90 : -90, 
      rotateY: isNext ? 90 : -90, 
      opacity: 0,
      transition: { duration: 1 },
    },
  });
  
  

  return (
    <section className="slider_02 relative">
      <div className="rev_slider_wrapper">
        <Slider ref={sliderRef} {...settings}>
          {/* Slide 1: Horizontal Slide */}
          <motion.div
            className="relative bg-center bg-cover slide-1"
            style={{ backgroundImage: 'url("/path/to/first-image.jpg")', height: '400px', width: '100%' }}
            initial="initial"
            animate="animate"
            exit="exit"
            variants={cubeTransition(currentSlide === 0)} // Use cubeTransition for horizontal effect
          >
            <div className="absolute inset-0 flex items-center left-20">
              <h2 className="text-black font-bold bg-white bg-opacity-50 Coursalfont p-4 rounded-lg">
                Step into Unikaa Beauty <br /> Salon, where your beauty <br /> meets our passion
              </h2>
            </div>
          </motion.div>

          {/* Slide 2: Vertical Slide */}
          <motion.div
            className="relative bg-center bg-cover slide-2"
            style={{ backgroundImage: 'url("/path/to/second-image.jpg")', height: '600px', width: '100%' }}
            initial="initial"
            animate="animate"
            exit="exit"
            variants={cube1Transition(currentSlide === 0)} // Use cube1Transition for vertical effect
          >
            <div className="absolute inset-0 flex items-center left-20">
              <h2 className="text-black  font-bold bg-white bg-opacity-50 p-4 rounded-lg Coursalfont">
                {/* A Fresh New Look Awaits You! <br /> Discover Our Expert Haircuts */}
                Step into Unikaa Beauty <br /> Salon, where your beauty <br /> meets our passion
              </h2>
            </div>
          </motion.div>

          {/* Slide 3: Vertical Slide */}
          <motion.div
            className="relative bg-center bg-cover slide-3"
            style={{ backgroundImage: 'url("/path/to/third-image.jpg")', height: '600px', width: '100%' }}
            initial="initial"
            animate="animate"
            exit="exit"
            variants={cube2Transition(currentSlide === 1)} // Use cubeTransition for horizontal effect again
          >
            <div className="absolute inset-0 flex items-center left-20">
              <h2 className="text-black  font-bold bg-white bg-opacity-50 p-4 rounded-lg Coursalfont">
                {/* Relax and Unwind with Our <br /> Exclusive Spa Services */}
                Step into Unikaa Beauty <br /> Salon, where your beauty <br /> meets our passion
              </h2>
            </div>
          </motion.div>
        </Slider>

        {/* Left Navigation Button */}
        <button
          onClick={prevSlide}
          className="absolute top-1/2 left-5 transform -translate-y-1/2 z-10 bg-white p-2 rounded-full shadow-md hover:bg-gray-200"
        >
          &#10094; {/* Left Arrow */}
        </button>

        {/* Right Navigation Button */}
        <button
          onClick={nextSlide}
          className="absolute top-1/2 right-5 transform -translate-y-1/2 z-10 bg-white p-2 rounded-full shadow-md hover:bg-gray-200"
        >
          &#10095; {/* Right Arrow */}
        </button>
      </div>
    </section>
  );
};

export default SliderComponent;
