import React, { useState } from "react";
import "../css/theme.css";
import Logo from "../Assets/Unikaa-Logo-14.png";

const Header = () => {
  // State to manage the visibility of the main menu and dropdown
  const [menuOpen, setMenuOpen] = useState(false);
  const [brandsDropdownOpen, setBrandsDropdownOpen] = useState(false);

  // Function to toggle the main menu visibility
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Function to toggle the dropdown for "Our Brands"
  const toggleBrandsDropdown = () => {
    setBrandsDropdownOpen(!brandsDropdownOpen);
  };

  console.log(menuOpen); // For debugging

  return (
    <header className="header_03 isSticky fixedHeader animated slideInDown">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 menu-col">
            <div className="logo2">
              <a href="index">
                <img src={Logo} alt="Makeover" style={{ width: "100px" }} />
              </a>
            </div>
            <button
              className="menu_btn lg:hidden focus:outline-none" // Hide button on larger screens
              onClick={toggleMenu}
            >
              {menuOpen ? (
                <i className="fa-solid fa-xmark text-2xl"></i> // Close icon
              ) : (
                <i className="fa-solid fa-bars text-2xl"></i> // Hamburger icon
              )}
            </button>

            <nav
              className={`mainMenu ${menuOpen ? "open" : "close"} lg:flex sm:none lg:justify-center`}
            >
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="about">About Us</a>
                </li>
                <li>
                  <a href="testimonial">Testimonial</a>
                </li>
                <li>
                  <a href="ourservices">OurServices</a>
                </li>
                {/* <li className="menu-item-has-children">
                  <a href="javascript:void(0);" onClick={toggleBrandsDropdown}>
                    Our Brands
                  </a>
                  {brandsDropdownOpen && (
                    <ul className="sub-menu">
                      <li>
                        <a href="mahafamilysalon">Maha Family Salon</a>
                      </li>
                      <li>
                        <a href="mahawomensalon">Maha Women's Salon</a>
                      </li>
                      <li>
                        <a href="yolo">Yolo</a>
                      </li>
                      <li>
                        <a href="thoya">Thoya</a>
                      </li>
                    </ul>
                  )}
                </li>
                <li>
                  <a href="training">Training Academy</a>
                </li> */}
              </ul>
              <div className="logo">
                <a href="/">
                  <img src={Logo} alt="Makeover" style={{ width: "100px" }} />
                </a>
              </div>
              <ul>
              <li>
                  <a href="trainingacademy">TrainingAcademy</a>
                </li>
                <li>
                  <a href="gallery">Gallery</a>
                </li>
                <li>
                  <a href="franchise">Franchise</a>
                </li>
             
                <li>
                  <a href="contactus">Contact Us</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
