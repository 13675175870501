import React, { useState } from "react";
import Imgbg from "../images/bg/bg6.jpeg";
// Import images from the src folder
import gl1 from "../images/gallery/gl1.jpeg";
import gl2 from "../images/gallery/gl2.jpeg";
import gl3 from "../images/gallery/gl3.jpeg";
import gl4 from "../images/gallery/gl4.jpeg";
import gl5 from "../images/gallery/gl5.jpeg";
import gl6 from "../images/gallery/gl6.jpeg";
import gl7 from "../images/gallery/newbg1.jpeg";
import gl8 from "../images/gallery/newbg5.jpeg";
import gl9 from "../images/gallery/newbg6.jpeg";

const Gallery = () => {
  // Define the galleryImages array with imported images
  const galleryImages = [
    { src: gl1, alt: "Modern hair cut" },
    { src: gl2, alt: "Modern hair cut" },
    { src: gl7, alt: "Modern hair cut" },
    { src: gl3, alt: "Modern hair cut" },
    { src: gl8, alt: "Modern hair cut" },
    { src: gl4, alt: "Modern hair cut" },
    { src: gl9, alt: "Modern hair cut" },
    { src: gl5, alt: "Modern hair cut" },
    { src: gl6, alt: "Modern hair cut" },
  
  ];

  const [loadingStates, setLoadingStates] = useState(
    new Array(galleryImages.length).fill(true)
  );

  const handleImageLoad = (index) => {
    const updatedLoadingStates = [...loadingStates];
    updatedLoadingStates[index] = false;
    setLoadingStates(updatedLoadingStates);
  };

  const handleImageError = (index) => {
    const updatedLoadingStates = [...loadingStates];
    updatedLoadingStates[index] = false;
    setLoadingStates(updatedLoadingStates);
  };

  return (
    <div>

<section
        className="page_banner bg-cover bg-center"
        style={{ backgroundImage: `url(${Imgbg})` }} // Use inline styles for dynamic image
      >
        <div className="container mx-auto py-12">
          <div className="flex flex-col lg:flex-row items-center justify-between">
            {/* Left Side Content */}
            <div className="lg:w-5/12 lg:ml-10 bg-white bg-opacity-70 p-8 rounded-lg shadow-lg">
              <h2 className="text-4xl lg:text-5xl font-bold text-gray-800">Gallery</h2>
              <p className="breadcrumbs text-lg mt-4 ml-1 text-gray-600">
                <a href="/" className="text-black hover:text-blue-600">
                  Home
                </a>
                <span>/</span>Gallery
              </p>
            </div>

            {/* Right Side Image (hidden on small screens) */}
            <div className="lg:w-6/12 mt-8 lg:mt-0">
              <img
                src="images/about/contactusbanner.png"
                alt=""
                className="w-full h-auto rounded-lg shadow-md"
              />
            </div>
          </div>
        </div>
      </section>

    <section className="gallerypage">
      <div className="container-fluid galleryContainer">
        <div className="flex flex-wrap -mx-2">
          {galleryImages.map((image, index) => (
            <div key={index} className="w-full sm:w-1/2 lg:w-1/3 p-2">
              <div className="gallerItem_2 mb-3 relative">
                {loadingStates[index] && (
                  <div className="bg-gray-200 animate-pulse h-0 w-full"></div>
                )}
                <img
                  src={image.src}
                  alt={image.alt}
                  className={`w-full h-[400px] object-cover ${loadingStates[index] ? "hidden" : ""}`}
                  loading="lazy"
                  onLoad={() => handleImageLoad(index)}
                  onError={() => handleImageError(index)}
                />
                <div className="galleryItem_2_btn absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50 hover:bg-opacity-75 transition-opacity duration-300 opacity-0 hover:opacity-100">
                  <a
                    className="popup_img"
                    href={image.src}
                    // alt={image.alt}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icofont-eye text-white text-2xl"></i>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
    </div>
  );
};

export default Gallery;
