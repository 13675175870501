import React from "react";
import backgroundImg from "../images/bg/7.png";
import shape1Img from "../images/bg/4.png";
import shape2Img from "../images/bg/5.png";
import aboutImg from "../images/about/about.jpeg";
import Flower from "../images/bg/flower.png";
import { useSpring, animated } from "react-spring";
import CountUp from "react-countup";
import Banner from "../images/bg/bg6.jpeg";



// 2div

import image1 from "../images/about/i2.png"
import image2 from "../images/about/i1.png"
import image3 from "../images/about/i3.png"
import Imgbg1 from "../images/about/1.jpg"

import Bg19 from "../images/bg/19.png"
// import InfoImage from '../images/about/Info-2.png'; 
import IconImage from '../images/icons/2.png';


const About = () => {
  const props1 = useSpring({
    from: { transform: "translatey(0%)" },
    to: { transform: "translatey(10%)" },
    config: { duration: 2000 },
    reset: true,
    loop: { reverse: true }
  });

  const facts = [
    { count: 10, label: 'Specialists', dataCount: 40 },
    { count: 5000, label: 'Happy Customers', dataCount: 100000 },
    { count: 15, label: 'Awards Won', dataCount: 70 },
    // { count: 5000, label: 'Students', dataCount: 20000 },
  ];

  const props2 = useSpring({
    from: { transform: "translatey(0%)" },
    to: { transform: "translatey(10%)" },
    config: { duration: 2000 },
    reset: true,
    loop: { reverse: true }
  });

  const props = useSpring({
    from: { transform: "translateX(-0%)" },
    to: { transform: "translateX(40%)" },
    config: { duration: 4000 },
    reset: true,
    loop: { reverse: true }
  });

  const props3 = useSpring({
    from: { transform: "translateX(-0%)" },
    to: { transform: "translateX(20%)" },
    config: { duration: 2000 },
    reset: false,
    loop: { reverse: true }
  });
  return (
    <div>
        <section
        className="page_banner  bg-cover object-cover w-100 bg-center "
        style={{ backgroundImage: `url(${Banner})`,  }} // Use inline styles for dynamic image
      >
        <div className="container mx-auto py-12">
          <div className="flex flex-col lg:flex-row items-center justify-between">
            {/* Left Side Content */}
            <div className="lg:w-5/12 lg:ml-10 bg-white bg-opacity-70 p-8 rounded-lg shadow-lg">
              <h2 className="text-4xl lg:text-5xl font-bold text-gray-800">About</h2>
              <p className="breadcrumbs text-lg mt-4 ml-1 text-gray-600">
                <a href="/" className="text-black hover:text-blue-600">
                  Home
                </a>
                <span>/</span>About
              </p>
            </div>

            {/* Right Side Image (hidden on small screens) */}
            <div className="lg:w-6/12 mt-8 lg:mt-0">
              <img
                src="images/about/contactusbanner.png"
                alt=""
                className="w-full h-auto rounded-lg shadow-md"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="commonSection aboutSection relative overflow-hidden mt-[50px]">

        <div className="container mx-auto relative z-10">
          <div className="row flex flex-wrap">
            <div className="col-lg-6 col-md-6 noPaddingRight clearfix w-full lg:w-1/2 flex justify-center">
              <div className="aboutImg float-right clearfix relative">
                <div className="shape1 mb-4">
                  <img src={shape1Img} alt="" className="w-auto h-auto" />
                </div>
                <div className="shape2 mb-4">
                  <img src={shape2Img} alt="" className="w-auto h-auto" />
                </div>
                <animated.div className="shape3 move_anim_two mb-[-100px]" style={props}>
                  <img src={Flower} alt="" className=" h-[250px] object-cover absolute" />
                </animated.div >
                <div className="abImg float-right">
                  <img src={aboutImg} alt="Makeover" className="w-[530px] h-[530px] object-cover" />
                </div>
                <div className="expCounter flex flex-col items-center">
                  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="196.000000pt" height="188.000000pt" viewBox="0 0 196.000000 188.000000" preserveAspectRatio="xMidYMid meet" className="mb-4">
                    <g transform="translate(0.000000,188.000000) scale(0.050000,-0.050000)" fill="#252525" stroke="none">
                      <path d="M1670 3710 c-709 -191 -1537 -962 -1631 -1517 -69 -402 41 -1322 178 -1505 209 -276 1124 -669 1557 -668 417 2 1303 405 1683 767 611 582 578 1429 -86 2196 -502 580 -1177 869 -1701 727z"></path>
                    </g>
                  </svg>
                  <div className="expContent funfact text-center" data-count="30">
                    <h2 className="text-4xl font-bold">
                      <span className="counter" > <CountUp
                        start={0}
                        end={5}
                        duration={2}
                        separator=","
                        easing="easeInOut"
                      /></span><i>+</i>
                    </h2>
                    <p>Years of Expert</p>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 w-full lg:w-1/2   ">
              <div className="abContent ">
                <h3 className="text-2xl font-semibold">About Founder</h3>
                <h2 className="text-3xl font-bold mt-2 headingFont">
                  Mr.G. <br /><span className=" font-[400] colorPrimary headingFont  text-[rgb(152,66,254,0.8)] text-[48px]">Agilan</span>
                </h2>
                <p className="leads text-lg font-medium headingFont">Founder &amp; Director of Unikaa Salon</p>
                <p>
                  <h4 className=' text-xl font-medium'>The Visionary Beginning:</h4>
                  In 2024, Mr. G. Agilan laid the foundation of Unikaa Salon, driven by a deep passion for beauty and wellness. With a keen eye on bridging the gap between quality and affordability in beauty services, he embarked on a mission to revolutionize the industry. His dedication to exploring the latest trends, techniques, and innovations set the stage for a transformative journey.
                  <br />  <h4 className=' text-xl font-medium'> The Evolution of Unikaa Beauty Salon:</h4>
                  Unikaa is the result of five years of relentless effort, extensive research, and an unwavering commitment to excellence. From its inception, the vision was clear: to create a welcoming space offering world-class beauty services accessible to everyone, regardless of their budget.

                </p>

                <a href="about-2.html" className="mo_btn mob_lg mob_shadow bg-blue-500 text-white py-2 px-4 rounded shadow-lg hover:bg-blue-600 transition duration-300">
                  <i className="icofont-long-arrow-right "></i> Know More
                </a>
                <animated.div className="layer_img absolute inset-0 flex justify-end" style={props1}>
                  <img src={backgroundImg} alt="" className=" object-cover w-[auto] h-[200px]" />
                </animated.div >

              </div>

            </div>

          </div>

        </div>





      </section>

      <section className="videoSection lg:mt-[50px]">
        <div className="container mx-auto">
          <div className="flex flex-wrap">
            <div className="w-full lg:w-1/2 ">
              <div className="videoContent lg:h-[530px]">
                <h3 className="text-xl font-bold">What We Provide</h3>
                <div className="serviceItem_02 my-4">
                  <div className="ib_box flex items-center">
                    <svg
                      version="1.0"
                      xmlns="http://www.w3.org/2000/svg"
                      width="167"
                      height="147"
                      viewBox="0 0 167 147"
                      preserveAspectRatio="xMidYMid meet"
                      className="w-14 h-14 text-gray-700"
                    >
                      <g transform="translate(0.000000,147.000000) scale(0.050000,-0.050000)">
                        <path
                          d="M1257 2920 c-50 -9 -109 -25 -130 -35 -20 -11 -95 -25 -165 -31 -523
                                        -45 -900 -630 -953 -1477 -43 -680 253 -1056 996 -1266 720 -204 1195 -98
                                        1691 377 805 772 854 1763 105 2149 -412 213 -1153 349 -1544 283z"
                        />
                      </g>
                    </svg>
                    <img
                      src={image1}
                      alt="Vision Icon"
                      className="w-16 ml-[-80px] relative"
                    />
                  </div>
                  <h3 className="text-lg font-semibold mt-2">
                    <a href="#" className="text-blue-500 hover:underline">
                      Our Vision - 2025
                    </a>
                  </h3>
                  <p>
                    100 Successful Entrepreneurs Dedicating Ourselves Building
                    International Salon reckoned with boutique Ambience,
                    Luxurious Services & Wow Customer Experiences.
                  </p>
                </div>

                <div className="serviceItem_02 my-4">
                  <div className="ib_box flex items-center">
                    <svg
                      version="1.0"
                      xmlns="http://www.w3.org/2000/svg"
                      width="167"
                      height="147"
                      viewBox="0 0 167 147"
                      preserveAspectRatio="xMidYMid meet"
                      className="w-14 h-14 text-gray-700"
                    >
                      <g transform="translate(0.000000,147.000000) scale(0.050000,-0.050000)">
                        <path
                          d="M1572 2919 c-708 -80 -1193 -291 -1416 -614 -534 -777 474 -2293
                                        1524 -2292 887 1 1576 436 1644 1037 101 889 -356 1757 -949 1804 -69 5 -160
                                        22 -202 38 -98 37 -396 51 -601 27z"
                        />
                      </g>
                    </svg>
                    <img
                      src={image3}
                      alt="Mission Icon"
                      className="w-16 ml-[-80px] relative"
                    />
                  </div>
                  <h3 className="text-lg font-semibold mt-2">
                    <a href="#" className="text-blue-500 hover:underline">
                      Our Mission - 2025
                    </a>
                  </h3>
                  <p>
                    150 Salons Making World Class Salon Experiences at
                    Affordable Luxury Delivered Right at the Convenience of our
                    Customer.
                  </p>
                </div>

                <div className="serviceItem_02 my-4">
                  <div className="ib_box flex items-center">
                    <svg
                      version="1.0"
                      xmlns="http://www.w3.org/2000/svg"
                      width="167"
                      height="147"
                      viewBox="0 0 167 147"
                      preserveAspectRatio="xMidYMid meet"
                      className="w-14 h-14 text-gray-700"
                    >
                      <g transform="translate(0.000000,147.000000) scale(0.050000,-0.050000)">
                        <path
                          d="M1572 2919 c-708 -80 -1193 -291 -1416 -614 -534 -777 474 -2293
                                           1524 -2292 887 1 1576 436 1644 1037 101 889 -356 1757 -949 1804 -69 5 -160
                                           22 -202 38 -98 37 -396 51 -601 27z"
                        />
                      </g>
                    </svg>
                    <img
                      src={image2}
                      alt="Goal Icon"
                      className="w-16 ml-[-80px] relative"
                    />
                  </div>
                  <h3 className="text-lg font-semibold mt-2">
                    <a href="#" className="text-blue-500 hover:underline">
                      Our Goal
                    </a>
                  </h3>
                  <p>
                    Creating 150+ successful entrepreneurs with Maha's salon by
                    2030.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-1/2 ">
              <div className="video_banner">
                <img
                  src={Imgbg1}
                  alt="Video Banner"
                  className="w-full lg:h-[530px]"
                />
              </div>
            </div>
            <animated.div
              style={props2}
              className="layer_img move_anim absolute z-50 ml-[-330px] mt-[85px] hidden lg:block"
            >
              <img
                src={Bg19}
                alt="Background Layer"
                className="inset-0"
              />
            </animated.div>
          </div>
        </div>

      </section>

      {/* <section className="commonSection historySection py-24"> 
        <div className="container mx-auto">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div className="sectionTitle text-center ">
                <animated.div className=" w-[10%] m-auto opacity-25 "
                  style={props3}> <img src={IconImage} alt="Industry Leader Icon" className="mx-auto absolute" /></animated.div>
                <h5 className="primaryFont">Industry Leader</h5>
                <h2 className="text-2xl font-bold">Our <span className="text-primary font-normal">Growth Story</span></h2>
              </div>
            </div>
          </div>
      
        </div>
      </section> */}




      <section className="commonSection funfactSection py-12 lg:mt-[40px] container m-auto">
        <div className="container mx-auto">
          <div className="row">
            <div className="col-lg-12">
              <div className="sectionTitle text-center mb-8">
              <animated.div className=" w-[10%] m-auto opacity-25 "
                  style={props3}> <img src={IconImage} alt="Industry Leader Icon" className="mx-auto absolute" /></animated.div>
                <h5 className="primaryFont">Our Progress</h5>
                <h2 className="text-3xl font-bold">
                  Milestones &amp;
                  <span className="text-primary font-normal"> Achievements</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row text-justify lg:flex lg:justify-around">
            {facts.map((fact, index) => (
              <div className="col-lg-3 col-md-6" key={index}>
                <div className="funfact fact_01 text-center" data-count={fact.dataCount}>
                  <h2 className="text-4xl font-bold">
                    <CountUp
                      start={0}
                      end={fact.count}
                      duration={2}
                      separator=","
                      easing="easeInOut"
                    />
                    <i>+</i>
                  </h2>
                  <h3 className="text-lg font-medium">{fact.label}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

    </div>
  );
};

export default About;
