// Footer.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faPhone, faEnvelope, faClock } from '@fortawesome/free-solid-svg-icons';
import Subfooter from "./Subfooter"
import Footerbg from "../images/bg/footer.png"
import { useSpring, animated } from 'react-spring';


const Footer = () => {


  const props = useSpring({
    from: { transform: 'translateX(-0%)' },
    to: { transform: 'translateX(10%)' },
    config: { duration: 5000 },
    reset: true,
    loop: { reverse: true }
  });

  return (
    <div><footer className="bg-[#9c3eff] text-white relative">
      <animated.div className="absolute inset-0 z-0"
        style={props}> <img src={Footerbg} alt="" className="w-[200px] h-full object-cover " />  </animated.div >

      <div className="container mx-auto py-10 relative z-10">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Maha Family Salon Section */}
          <div className="flex flex-col">
            <h3 className="text-xl font-bold mb-4 m-auto">Quick links</h3>
            <div className="info_box flex items-start mb-4">
              <div className="bg-[#008ba8] rounded-full p-2 mr-2">
                <FontAwesomeIcon icon={faLocationDot} className="text-2xl text-white" />
              </div>
              <p>Home</p>
            </div>
            <div className="info_box flex items-start mb-4">
              <div className="bg-[#008ba8] rounded-full p-2 mr-2">
                <FontAwesomeIcon icon={faPhone} className="text-2xl text-white" />
              </div>
              <p>AboutUs</p>
            </div>
            {/* <div className="info_box flex items-start mb-4">
              <div className="bg-[#008ba8] rounded-full p-2 mr-2">
                <FontAwesomeIcon icon={faEnvelope} className="text-2xl text-white" />
              </div>
              <p>Training Academy
              </p>
            </div> */}
            {/* <div className="info_box flex items-start mb-4">
              <div className="bg-[#008ba8] rounded-full p-2 mr-2">
                <FontAwesomeIcon icon={faClock} className="text-2xl text-white" />
              </div>
              <p>9:00 am - 9:00 pm</p>
            </div> */}
          </div>

          {/* Maha Women's Salon Section */}
          <div className="flex flex-col">
            <h3 className="text-xl font-bold mb-4 text-center">Unikaa Salon</h3>
            <div className="info_box flex items-start mb-4">
              <div className="bg-[#008ba8] rounded-full p-2 mr-2">
                <FontAwesomeIcon icon={faLocationDot} className="text-2xl text-white" />
              </div>
              <p>NO:131 1st floor DB road(near ramraj cotton showroom) RS puram , coimbatore 641002</p>
            </div>
            {/* <div className="info_box flex items-start mb-4">
              <div className="bg-[#008ba8] rounded-full p-2 mr-2">
                <FontAwesomeIcon icon={faPhone} className="text-2xl text-white" />
              </div>
              <p>Call Us<br />+91 98849 69381</p>
            </div>
            <div className="info_box flex items-start mb-4">
              <div className="bg-[#008ba8] rounded-full p-2 mr-2">
                <FontAwesomeIcon icon={faEnvelope} className="text-2xl text-white" />
              </div>
              <p>Mail Us<br />mani2102@gmail.com</p>
            </div> */}
            <div className="info_box flex items-start mb-4">
              <div className="bg-[#008ba8] rounded-full p-2 mr-2">
                <FontAwesomeIcon icon={faClock} className="text-2xl text-white" />
              </div>
              <p>10:00 am - 9:00 pm</p>
            </div>
          </div>

          {/* Yolo Section */}
          <div className="flex flex-col">
            <h3 className="text-xl font-bold mt-0 mb-4 m-auto">Contact Us</h3>
            {/* <div className="info_box flex items-start mb-4">
              <div className="bg-[#008ba8] rounded-full p-2 mr-2">
                <FontAwesomeIcon icon={faLocationDot} className="text-2xl text-white" />
              </div>
              <p>NO:131 1st floor DB road(near ramraj cotton showroom) RS puram , coimbatore 641002</p>
            </div> */}
            <div className="info_box flex items-start mb-4">
              <div className="bg-[#008ba8] rounded-full p-2 mr-2">
                <FontAwesomeIcon icon={faPhone} className="text-2xl text-white" />
              </div>
              <p>Call Us<br />+91 70927 70199</p>
            </div>
            <div className="info_box flex items-start mb-4">
              <div className="bg-[#008ba8] rounded-full p-2 mr-2">
                <FontAwesomeIcon icon={faEnvelope} className="text-2xl text-white" />
              </div>
              <p>Mail Us<br />unikaabeauty@gmail.com</p>
            </div>
            {/* <div className="info_box flex items-start mb-4">
              <div className="bg-[#008ba8] rounded-full p-2 mr-2">
                <FontAwesomeIcon icon={faClock} className="text-2xl text-white" />
              </div>
              <p>10:00 am - 9:00 pm</p>
            </div> */}
          </div>
        </div>
      </div>



    </footer>

      <Subfooter />
    </div>
  );
};

export default Footer;
