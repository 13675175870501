// Copyright.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons'; // Importing brand icons
import { faPhone } from '@fortawesome/free-solid-svg-icons'; // Importing solid icon

const Subfooter = () => {
  return (
    <section className="bg-[#9c3eff] text-gray-300 py-4">
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          <div className="text-sm">
            <p>
              © 2024 Unikaa Beauty | All Rights Reserved 
           
            </p>
          </div>
          <div className="flex space-x-4">
            <a
              href="https://www.youtube.com/@UnikaaBeauty"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faYoutube} className="text-xl hover:text-blue-400" />
            </a>
            {/* <a href="tel:+919840094466">
              <FontAwesomeIcon icon={faPhone} className="text-xl hover:text-blue-400" />
            </a> */}
            <a
              href="https://www.instagram.com/unikaabeauty_covai/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} className="text-xl hover:text-blue-400" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Subfooter;
