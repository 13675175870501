import React from "react";
import Imgbg from "../images/bg/bg6.jpeg";
import Icon1 from "../images/icons/1.png";

const OurServices = () => {
  const services = [
    {
      category: "Hair Care Services",
      items: [
        "Haircuts (Customized for Men, Women & Kids)",
        "Hair Styling (Straightening, Smoothing, Rebonding, and Keratin Treatments)",
        "Hair Coloring & Highlights",
        "Scalp Treatments & Dandruff Care"
      ]
    },
    {
      category: "Facials & Skin Care",
      items: [
        "Hydrating Facials (Pure Moist, Glow Boost)",
        "Skin Brightening Treatments",
        "Anti-Aging Facials",
        "De-Tan Face & Neck Care"
      ]
    },
    {
      category: "Bridal & Event Makeovers",
      items: [
        "Bridal Makeup Packages",
        "Party & Reception Looks",
        "Hairstyling & Saree Draping"
      ]
    },
    {
      category: "Hand & Feet Care",
      items: [
        "Manicure & Pedicure (Classic & Advanced)",
        "Nail Art & Gel Polish",
        "Foot Spa & Reflexology",
        "Waxing & Threading",
        "Full Body Waxing",
        "Eyebrow Shaping & Threading"
      ]
    },
    {
      category: "Beauty Packages",
      items: [
        "Customized Beauty Packages",
        "Combo Offers for Hair, Skin & Bridal Care"
      ]
    }
  ];

  const ServiceList = ({ category, items }) => (
    <div className="my-8">
      <div className="bg-[rgb(142,80,254,0.3)] rounded-lg shadow-lg overflow-hidden transform transition duration-300 hover:scale-105 hover:shadow-xl hover:bg-[rgb(142,80,254,0.3)] flex flex-col h-full">
        <div className="p-6 flex-1"> {/* Ensure the card grows to fill available height */}
          <h3 className="text-2xl font-semibold text-gray-800 mb-4">{category}</h3>
          <ul className="list-disc pl-6 space-y-2">
            {items.map((item, index) => (
              <li key={index} className="text-gray-600">{item}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {/* Page Banner */}
      <section
        className="page_banner bg-cover bg-center"
        style={{ backgroundImage: `url(${Imgbg})` }}
      >
        <div className="container mx-auto py-12">
          <div className="flex flex-col lg:flex-row items-center justify-between">
            {/* Left Side Content */}
            <div className="lg:w-5/12 lg:ml-10 bg-[white] bg-opacity-70 p-8 rounded-lg shadow-lg">
              <h2 className="text-4xl lg:text-5xl font-bold text-gray-800">Our Services</h2>
              <p className="breadcrumbs text-lg mt-4 ml-1 text-gray-600">
                <a href="/" className="text-black hover:text-blue-600">
                  Home
                </a>
                <span>/</span>Our Services
              </p>
            </div>

            {/* Right Side Image (hidden on small screens) */}
            <div className="lg:w-6/12 mt-8 lg:mt-0">
              {/* <img
                src="images/about/contactusbanner.png"
                alt="Salon Image"
                className="w-full h-auto rounded-lg shadow-md"
              /> */}
            </div>
          </div>
        </div>
      </section>

      {/* Welcome Section */}
      <section className="commonSection welcomeSection py-12">
        <div className="container mx-auto">
          <div className="flex justify-center">
            <div className="lg:w-10/12">
              <div className="sectionTitle text-center">
                <img
                  src={Icon1}
                  alt="Unikaa Salon Icon"
                  className="mx-auto mb-[-50px] bounce-animation w-[100px] object-cover"
                />
                <h5 className="font-semibold tracking-wider">Welcome to Unikaa Beauty Salon</h5>
                <h2 className="text-3xl font-bold mt-4 leading-[60px] headingFont text-[60px]">
                  where we offer a wide range of{" "}
                  <span className="text-primary font-normal text-[rgb(152,66,254,0.8)]">
                    services to enhance your beauty and confidence.
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Service Categories Section */}
      <section className="servicesSection py-12">
        <div className="container mx-auto flex flex-col items-center justify-center">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
            {services.map((service, index) => (
              <ServiceList key={index} category={service.category} items={service.items} />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default OurServices;
