import React from "react";
import Imgbg from "../images/bg/bg6.jpeg";

const Trainingacademy = () => {
  // Define Diploma Courses
  const diplomaCourses = [
    {
      title: "Course A",
      duration: "1 year",
      details: [
        "Diploma in Cosmetology",
        "Advanced Hair Care",
        "Professional Makeup"
      ]
    },
    {
      title: "Course B",
      duration: "1 year",
      details: [
        "Diploma in Cosmetology",
        "Advanced Hair Care",
        "Professional Tattoo Training"
      ]
    },
    {
      title: "Course C",
      duration: "1 year",
      details: [
        "Diploma in Cosmetology",
        "Skin Care",
        "Spa",
        "Professional Makeup"
      ]
    },
    {
      title: "Course D",
      duration: "1 year",
      details: [
        "Diploma in Cosmetology",
        "Advanced Skin Care",
        "Spa",
        "Professional Tattoo Training"
      ]
    }
  ];

  // Define Choose Any 2 Courses (6 Months Duration)
  const chooseAny2Courses = [
    "Cosmetology & Hair Dressing",
    "Advanced Hair Care & Styling",
    "Professional Makeup, Hairdo & Saree Draping",
    "Expert Tattoo Artist Training",
    "Advanced Skin Care & Therapy"
  ];

  // Define Short Term Courses
  const shortTermCourses = [
    "Mehendi Designs",
    "Nail Art & Designs",
    "Hair Extensions",
    "Hair Styles",
    "Hair Cuts",
    "Hair Coloring",
    "Advanced Facials",
    "Advanced Makeup Techniques"
  ];

  return (
<div>
<section
        className="page_banner bg-cover bg-center"
        style={{ backgroundImage: `url(${Imgbg})` }}
      >
        <div className="container mx-auto py-12">
          <div className="flex flex-col lg:flex-row items-center justify-between">
            {/* Left Side Content */}
            <div className="lg:w-5/12 lg:ml-10 bg-white bg-opacity-70 p-8 rounded-lg shadow-lg">
              <h2 className="text-4xl lg:text-5xl font-bold text-gray-800">Training Academy</h2>
              <p className="breadcrumbs text-lg mt-4 ml-1 text-gray-600">
                <a href="/" className="text-black hover:text-blue-600">
                  Home
                </a>
                <span>/</span>Training Academy
              </p>
            </div>

            {/* Right Side Image (hidden on small screens) */}
            <div className="lg:w-6/12 mt-8 lg:mt-0">
              {/* <img
                src="images/about/contactusbanner.png"
                alt="Salon Image"
                className="w-full h-auto rounded-lg shadow-md"
              /> */}
            </div>
          </div>
        </div>
      </section>

    <div className="container mx-auto py-12">


      {/* Diploma Courses Section */}
      <h2 className="text-3xl font-bold text-center mb-8">Diploma Courses</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {diplomaCourses.map((course, index) => (
          <div
            key={index}
            className="bg-[rgb(142,80,254,0.3)] rounded-lg shadow-lg transform transition duration-300 hover:scale-105 hover:shadow-xl hover:bg-[rgb(142,80,254,0.3)]"
          >
            <div className="p-6">
              <h3 className="text-2xl font-semibold text-gray-800 mb-4">{course.title}</h3>
              <p className="text-gray-600 mb-4">Duration: {course.duration}</p>
              <ul className="list-disc pl-6 space-y-2">
                {course.details.map((item, idx) => (
                  <li key={idx} className="text-gray-600">{item}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>


    <div className="container mx-auto py-12">
      {/* Choose Any 2 Courses Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
        {/* 6 Months Duration */}
        <div
          className="bg-[rgb(142,80,254,0.3)] rounded-lg shadow-lg transform transition duration-300 hover:scale-105 hover:shadow-xl hover:bg-[rgb(142,80,254,0.3)]"
        >
          <div className="p-6">
            <h2 className="text-3xl font-bold text-center mb-8">Choose Any 2 Courses from the Following</h2>
            <h3 className="text-2xl font-semibold text-gray-800 mb-4">Duration: 6 Months</h3>
            <ul className="list-disc pl-6 space-y-2">
              {chooseAny2Courses.map((course, index) => (
                <li key={index} className="text-gray-600">{course}</li>
              ))}
            </ul>
          </div>
        </div>

        {/* Short Term Courses Section */}
        <div
          className="bg-[rgb(142,80,254,0.3)] rounded-lg shadow-lg transform transition duration-300 hover:scale-105 hover:shadow-xl hover:bg-[rgb(142,80,254,0.3)]"
        >
          <div className="p-6">
            <h2 className="text-3xl font-bold text-center mb-8">Short Term Courses</h2>
            <h3 className="text-2xl font-semibold text-gray-800 mb-4">Short Term Courses</h3>
            <ul className="list-disc pl-6 space-y-2">
              {shortTermCourses.map((course, index) => (
                <li key={index} className="text-gray-600">{course}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>




    </div>
    </div>
  );
};

export default Trainingacademy;

