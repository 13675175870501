import React from 'react'
import Coursal from "./subpages/Cubeslider"
import Icon1 from "../images/icons/1.png"


import "../css/theme.css"; // Assuming you may still want to include this for global styles
import backgroundImg from '../images/bg/7.png';
import shape1Img from '../images/bg/4.png';
import shape2Img from '../images/bg/5.png';
import aboutImg from '../images/about/about.jpeg';
import Flower from "../images/bg/flower.png"
import { useSpring, animated } from 'react-spring';

import icon1 from "../images/unikaa-icons/1-removebg-preview.png";
import icon2 from '../images/homeserviceicons/serviceicon2.png';
import icon3 from '../images/unikaa-icons/2-removebg-preview.png';
import icon4 from '../images/unikaa-icons/3-removebg-preview.png';
import icon5 from '../images/unikaa-icons/4-removebg-preview.png';
import icon6 from '../images/unikaa-icons/10-removebg-preview.png';
import icon7 from '../images/unikaa-icons/6-removebg-preview.png';
import icon8 from '../images/unikaa-icons/8-removebg-preview.png';
import icon9 from '../images/unikaa-icons/5-removebg-preview.png';
import icon10 from '../images/homeserviceicons/serviceicon10.png';
import bgour from "../images/icons/2.png";
import CountUp from 'react-countup';
import Animeicon from "../images/icons/2.png"
import Services from './subpages/service';

import SliderComponent from '../pages/sliderpackage/Slidernew';



const Home = () => {
  const facts = [
    { count: 10, label: 'Specialists', dataCount: 40 },
    { count: 5000, label: 'Happy Customers', dataCount: 100000 },
    { count: 15, label: 'Awards Won', dataCount: 70 },
    // { count: 5000, label: 'Students', dataCount: 20000 },
  ];
  const props1 = useSpring({
    from: { transform: 'translatey(0%)' },
    to: { transform: 'translatey(10%)' },
    config: { duration: 2000 },
    reset: true,
    loop: { reverse: true }
  });


  const props = useSpring({
    from: { transform: 'translateX(-0%)' },
    to: { transform: 'translateX(40%)' },
    config: { duration: 4000 },
    reset: true,
    loop: { reverse: true }
  });



  const services = [
    {
      title: 'Bridal Make Up',
      description: "We feel that every woman should get attention and compliments from people on her big day, and we work very hard to achieve the same for you. Our bridal makeup artists are ready to turn you into a ‘Diva.’",
      imgSrc: icon1,
    },
    // {
    //   title: 'Hair Transplant',
    //   description: "Are you losing self-confidence because of baldness, or are you tired of your heavy hair falling? Whatever hair-related problems you’re facing, we are here to help you with our pain-less and scar-less hair transplant treatments.",
    //   imgSrc: icon2,
    // },
    {
      title: 'Stem Cell Skin Lightening',
      description: "Every woman desires flawless clear skin and perfect complexion. Now, you can achieve your dream of bright skin with stem cell skin lightening treatment. It fades tanning, pigmentation marks, dark spots, and age spots, leaving your skin radiant and luminous.",
      imgSrc: icon3,
    },
    {
      title: 'Micro Blading (semi-permanent)',
      description: "Eyebrows are like frames to your eyes and have an immense aesthetic quality. The permanent eyebrow is among the commonest of the beauty treatments done by us. It gives you a glamorous look to your natural appearance.",
      imgSrc: icon4,
    },
    {
      title: 'Hair Fixing For Men and Women',
      description: "Wigs and Hairpieces (Hair Extensions) are very big business at the moment and have become a status symbol. The very fact that all the celebrities endorse using wigs, hairpieces or extensions gives these products celebrity status, hence every young lady wants them.",
      imgSrc: icon5,
    },
    {
      title: 'BB Glow',
      description: "If you’re looking to slow down the signs of aging, speed up weight loss, or simply improve your complexion, our Cryo Ice Therapy is available to help you achieve different beauty goals.",
      imgSrc: icon6,
    },
    {
      title: 'Nail Art',
      description: "Expertise in nail designs, pedicures, foot massages, extensions, eyelash extensions, and lip tinting. Elevate your beauty experience. Services we offer are complete nail care like Acrylics , gels, shellac, Manicures and pedicures.",
      imgSrc: icon7,
    },
    {
      title: 'Hair Extensions and Weaving',
      description: "If you're looking to increase your hair length immediately in the most quality and effortless process, unikaa's Hair Extension solution is custom-made just for you. The process involves increasing the hair length with use of extensions.",
      imgSrc: icon8,
    },
    {
      title: 'Aesthetic',
      description: "We understand that every individual has unique aesthetic preferences. That's why we offer personalized consultations to understand your vision and goals. Our experienced stylists will listen attentively to your desires and provide expert guidance to help you achieve the aesthetic you've always dreamed of.",
      imgSrc: icon9,
    },
    // {
    //   title: 'Tattoo',
    //   description: "Finding a fantastic tattoo design at the greatest price could be chaotic and exhausting, choosing the appropriate tattoo studio in India or the proper tattoo artist can be perplexing.",
    //   imgSrc: icon10,
    // },
  ];



  return (
    <div>

      {/* <SliderComponent/> */}
      
      <Coursal />



      <section className="commonSection welcomeSection py-12">
        <div className="container mx-auto">
          <div className="flex justify-center">
            <div className="lg:w-10/12">
              <div className="sectionTitle text-center">
                <img src={Icon1} alt="unikaa Salon Icon" className="mx-auto mb-[-50px] bounce-animation w-[100px] object-cover" />
                <h5 className=" font-semibold space tracking-wider ">Welcome to unikaa salon!!!</h5>
                <h2 className="text-3xl font-bold mt-4 leading-[54px] headingFont text-[60px]">
                  Where Beauty
                  <span className="text-primary font-normal text-[rgb(152,66,254,0.8)]"> Your Haven of Beauty and Elegance</span>
                </h2>
                <p className="mt-4 text-gray-700">
                  At Unikaa Salon, we believe beauty is personal, and every client deserves a tailored experience. We bring a fresh and modern touch to salon services, offering Haircare, Skincare, Nail, Body Wellness, and Specialized Treatments.
                </p>
              </div>
            </div>
          </div>


          <div className="flex flex-wrap mt-8 lg:w-[75%] lg:m-auto sm:w-[100%]">
            <div className="w-full md:w-1/2 p-4">
              <div className="actionBox  rounded-lg shadow-lg abBg2" >
                <h3 className="text-xl font-semibold">Unikaa Beauty Salon</h3>
                <p className="mt-2 text-gray-600">
                  More Than Just a Salon Experience
                  <br />
                  Step in for more than a trim, style, or pamper session.<br />
                  At Unikaa, we’ve got your beauty needs covered from head to toe!
                </p>
                <a href="about" className="mo_btn inline-block mt-4 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-500 transition">
                  <i className="icofont-long-arrow-right"></i> Read More
                </a>
              </div>
            </div>
            <div className="w-full md:w-1/2 p-4">
              <div className="actionBox  rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold leading-8">Rediscover Your Confidence with Our Exclusive Care</h3>
                <p className="mt-2 text-gray-800">
                  Experience personalized treatments and therapies<br /> designed to bring out the best in you.
                </p>
                <a href="about" className="mo_btn inline-block mt-4 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-500 transition">
                  <i className="icofont-long-arrow-right"></i> Read More
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>





      <section className="commonSection aboutSection relative overflow-hidden mt-[50px] py-20">

        <div className="container mx-auto relative z-10">
          <div className="row flex flex-wrap">
            <div className="col-lg-6 col-md-6 noPaddingRight clearfix w-full lg:w-1/2 flex justify-center">
              <div className="aboutImg float-right clearfix relative">
                <div className="shape1 mb-4">
                  <img src={shape1Img} alt="" className="w-auto h-auto" />
                </div>
                <div className="shape2 mb-4">
                  <img src={shape2Img} alt="" className="w-auto h-auto" />
                </div>
                <animated.div className="shape3 move_anim_two mb-[-100px]" style={props}>
                  <img src={Flower} alt="" className=" h-[250px] object-cover absolute" />
                </animated.div >
                <div className="abImg float-right">
                  <img src={aboutImg} alt="Makeover" className="w-[530px] h-[530px] object-cover" />
                </div>
                <div className="expCounter flex flex-col items-center">
                  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="196.000000pt" height="188.000000pt" viewBox="0 0 196.000000 188.000000" preserveAspectRatio="xMidYMid meet" className="mb-4">
                    <g transform="translate(0.000000,188.000000) scale(0.050000,-0.050000)" fill="#252525" stroke="none">
                      <path d="M1670 3710 c-709 -191 -1537 -962 -1631 -1517 -69 -402 41 -1322 178 -1505 209 -276 1124 -669 1557 -668 417 2 1303 405 1683 767 611 582 578 1429 -86 2196 -502 580 -1177 869 -1701 727z"></path>
                    </g>
                  </svg>
                  <div className="expContent funfact text-center" data-count="30">
                    <h2 className="text-4xl font-bold">
                      <span className="counter" > <CountUp
                        start={0}
                        end={5}
                        duration={2}
                        separator=","
                        easing="easeInOut"
                      /></span><i>+</i>
                    </h2>
                    <p>Years of Expert</p>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 w-full lg:w-1/2   ">
              <div className="abContent ">
                <h3 className="text-2xl font-semibold">About Founder</h3>
                <h2 className="text-3xl font-bold mt-2 headingFont">
                  Mr.G. 
                  {/* <br /> */}
                  <span className=" font-[400] colorPrimary headingFont  text-[rgb(152,66,254,0.8)] text-[48px]">Agilan</span>
                </h2>
                <p className="leads text-lg font-medium headingFont">Founder &amp; Director of Unikaa Salon</p>
                <p className='leading-[30px]'>
                  <h4 className=' text-xl font-medium '>The Visionary Beginning:</h4>
                  In 2024, Mr. G. Agilan laid the foundation of Unikaa Salon, driven by a deep passion for beauty and wellness. With a keen eye on bridging the gap between quality and affordability in beauty services, he embarked on a mission to revolutionize the industry. His dedication to exploring the latest trends, techniques, and innovations set the stage for a transformative journey.
                  <br />  <h4 className=' text-xl font-medium'> The Evolution of Unikaa Beauty Salon:</h4>
                  Unikaa is the result of five years of relentless effort, extensive research, and an unwavering commitment to excellence. From its inception, the vision was clear: to create a welcoming space offering world-class beauty services accessible to everyone, regardless of their budget.

                </p>

                <a href="about" className="mo_btn mob_lg mob_shadow hover: cursor-pointer bg-blue-500 text-white py-2 px-4 rounded shadow-lg hover:bg-blue-600 transition duration-300">
                  <i className="icofont-long-arrow-right "></i>Know More
                </a>
                <animated.div className="layer_img absolute inset-0 flex justify-end" style={props1}>
                  <img src={backgroundImg} alt="" className=" object-cover w-[auto] h-[200px]" />
                </animated.div >

              </div>

            </div>

          </div>

        </div>





      </section>

      <section className="commonSection funfactSection py-12" >
        <div className="container mx-auto">
          <div className="row">
            <div className="col-lg-12">
              <div className="sectionTitle text-center mb-8">
                <img src={Animeicon} alt="unikaa Salon Icon" className="mx-auto mb-[-50px] bounce-animation" />
                <h5 className="primaryFont font-bold">Our Progress</h5>
                <h2 className="text-[48px] font-bold headingFont">
                  Milestones &amp;
                  <span className="text-primary font-normal"> Achievements</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row text-justify lg:flex lg:justify-around">
            {facts.map((fact, index) => (
              <div className="col-lg-3 col-md-6" key={index}>
                <div className="funfact fact_01 text-center headingFont" data-count={fact.dataCount}>
                  <h2 className="text-4xl font-bold">
                    <CountUp
                      start={0}
                      end={fact.count}
                      duration={2}
                      separator=","
                      easing="easeInOut"
                    />
                    <i>+</i>
                  </h2>
                  <h3 className="text-lg font-medium headingFont" >{fact.label}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>



      <div className="commonSection serviceSection3 hasBeforeDecoration">
        <div className="container mx-auto p-4">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className=" text-justify">
                <div className="sectionTitle">
                  {/* <img src={icon2} alt="Service Icon" /> */}
                  <h2 className="text-2xl font-bold text-center">
                    Our
                    <span className="text-primary font-normal text-center"> Top Services</span>
                  </h2>
                  <p className=' w-[80%] m-auto text-center'>
                    We have listed below, some of the Top Services collectively offered by our Brands. We feel that every woman should get attention and compliments from people on her big day, and we work very hard to achieve the same for you. Our bridal makeup artists are ready to turn you into a ‘Diva.’
                  </p>
                </div>
              </div>
            </div>



            {/* Grid layout for services */}
            <div className="col-lg-6 col-md-6">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols2 gap-6 mt-4">
                {services.map((service, index) => (
                  <div className="serviceItem_01 sIlg text-justify" key={index}>
                    <div className="ib_box relative  flex justify-center ">
                      <img src={service.imgSrc} alt={`${service.title} Icon`} className="w-36  relative z-10  " />
                    </div>
                    <h3 className="text-lg font-semibold text-center">
                      <a href="#">{service.title}</a>
                    </h3>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>




    </div>
  )
}

export default Home